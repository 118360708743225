<template>
    <center>
    <v-layout column>
        <v-flex text-center>
            <img src="@/assets/invalid-address.png" :width="this.$vuetify.breakpoint.mdAndUp ? '50%' : '100%'" alt="Invalid address">
        </v-flex>
        <v-flex>
            <label style="font-size:2rem">OOPS!! Sorry you entered wrong address</label>
        </v-flex>
    </v-layout>
    </center>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>